import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { BackgroundFrame } from '@components/frames/background-frame';
import { FRAME_THEME_COLOR } from '@nandosaus/constants';
import { Box, Col, Container, Flex, Row } from '../grid';
import { CardListing } from '../card-listing';
import { cmsImagePropTypes } from '../../prop-types';
import { HeadingWithLineHighlights } from '../heading-with-line-highlights';
import { LinkCard } from '../card';
import { linkCardItemsQuery, matrixBlockQuery } from '../../content/query-snippets';
import { RichText } from '../typography';

const LinkCardBlock = ({ heading, body, linkCardItems, hasBackgroundDecal }) => {
  const { YELLOW } = FRAME_THEME_COLOR;

  const children = (
    <Container py={4}>
      <Flex my={1} flexDirection="column" justifyContent="center" alignItems="center">
        <Box width={{ xs: 8 / 12, md: 6 / 12 }} textAlign="center">
          <HeadingWithLineHighlights heading={heading} />
          {body && <RichText keepMargins>{body}</RichText>}
        </Box>
      </Flex>
      <Row my={1}>
        <Col width={{ xs: 12 / 12, md: 10 / 12 }} offset={[0, 0, 1 / 12]}>
          {!isEmpty(linkCardItems) && (
            <CardListing
              cardAlignment="center"
              CardComponent={LinkCard}
              cardWidth={{ xs: 12 / 12, md: linkCardItems.length <= 4 ? 3 / 12 : 4 / 12 }}
              imageTransformIsRequired
              items={linkCardItems}
            />
          )}
        </Col>
      </Row>
    </Container>
  );

  return hasBackgroundDecal ? (
    <BackgroundFrame showFireIcon variant={YELLOW}>
      {children}
    </BackgroundFrame>
  ) : (
    children
  );
};

LinkCardBlock.propTypes = {
  body: PropTypes.string,
  heading: PropTypes.string.isRequired,
  hasBackgroundDecal: PropTypes.bool,
  linkCardItems: PropTypes.arrayOf(
    PropTypes.shape({
      externalLink: PropTypes.string,
      heading: PropTypes.string,
      image: PropTypes.arrayOf(cmsImagePropTypes),
    })
  ).isRequired,
};

LinkCardBlock.defaultProps = {
  body: null,
  hasBackgroundDecal: true,
};

LinkCardBlock.query = `
  ... on contentMatrix_linkCardBlock_BlockType {
    ${matrixBlockQuery}
    ${linkCardItemsQuery}
    body
    heading
    hasBackgroundDecal
  }
`;

export { LinkCardBlock };

import { PropTypes } from 'prop-types';
import React from 'react';

import { cmsImagePropTypes } from '../../../prop-types';
import { MosaicCard } from '../../card';

const gridSizes = {
  sm: {
    width: 245,
    height: 240,
  },
  md: {
    width: 379,
    height: 360,
  },
  lg: {
    width: 513,
    height: 480,
  },
};

const gridPosition = {
  0: {
    image: {
      gridColumn: { lg: '1 / 5' },
      gridRow: { lg: '4 / 8' },
      ...gridSizes.lg,
    },

    content: {
      gridColumn: { lg: '1 / 5' },
      gridRow: { lg: '8 / 9' },
    },
  },
  1: {
    image: {
      gridColumn: { lg: '5 / 8' },
      gridRow: { lg: '2 / 5' },
      ...gridSizes.md,
    },

    content: {
      gridColumn: { lg: '5 / 8' },
      gridRow: { lg: '5 / 6' },
    },
  },
  2: {
    image: {
      gridColumn: { lg: '8 / 10' },
      gridRow: { lg: '3 / 5' },
      ...gridSizes.sm,
    },

    content: {
      gridColumn: { lg: '8 / 10' },
      gridRow: { lg: '5 / 6' },
    },
  },
  3: {
    image: {
      gridColumn: { lg: '5 / 7' },
      gridRow: { lg: '6 / 8' },
      ...gridSizes.sm,
    },

    content: {
      gridColumn: { lg: '5 / 7' },
      gridRow: { lg: '8 / 9' },
    },
  },
  4: {
    image: {
      gridColumn: { lg: '7 / 11' },
      gridRow: { lg: '6 / 10' },
      ...gridSizes.sm,
    },

    content: {
      gridColumn: { lg: '7 / 11' },
      gridRow: { lg: '10 / 11' },
    },
  },
  5: {
    image: {
      gridColumn: { lg: '2 / 4' },
      gridRow: { lg: '9 / 11' },
      ...gridSizes.sm,
    },

    content: {
      gridColumn: { lg: '2 / 4' },
      gridRow: { lg: '11 / 12' },
    },
  },
  6: {
    image: {
      gridColumn: { lg: '4 / 7' },
      gridRow: { lg: '9 / 12' },
      ...gridSizes.md,
    },

    content: {
      gridColumn: { lg: '4 / 7' },
      gridRow: { lg: '12 / 13' },
    },
  },
  7: {
    image: {
      gridColumn: { lg: '7 / 10' },
      gridRow: { lg: '11 / 14' },
      ...gridSizes.md,
    },

    content: {
      gridColumn: { lg: '7 / 10' },
      gridRow: { lg: '14 / 15' },
    },
  },
};

const MosaicItem = ({ item, index }) => {
  return (
    <MosaicCard
      item={item}
      gridPosition={gridPosition[index]}
      display={{ _: 'block', lg: 'contents' }}
      mb={{ _: '1rem', lg: 0 }}
    />
  );
};

MosaicItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({ image: cmsImagePropTypes, heading: PropTypes.string }).isRequired,
};

export { MosaicItem };

import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex } from '../grid';
import { Icon } from '../icons';
import { Subheading } from '../typography';

const Accordion = ({ title, defaultExpanded, onStateChange, children, disabled }) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleOnClick = () => {
    setExpanded(!expanded);
    onStateChange(expanded);
  };

  return (
    <div>
      <SummaryWrapper
        py={1}
        alignItems="center"
        justifyContent="space-between"
        onClick={handleOnClick}
        disabled={disabled}
      >
        <Subheading variant={3}>{title}</Subheading>
        <Icon name="chevron" height="24px" width="24px" rotate={expanded ? '180deg' : '0'} />
      </SummaryWrapper>
      {expanded && <Box pb={2}>{children}</Box>}
    </div>
  );
};

const SummaryWrapper = styled(Flex)`
  ${({ disabled }) => !disabled && `cursor: pointer;`}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.grey300};
      cursor: not-allowed;
      pointer-events: none;

      svg {
        fill: ${theme.colors.grey300};
      }
    `}
`;

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool,
  disabled: PropTypes.bool,
  onStateChange: PropTypes.func,
  title: PropTypes.string.isRequired,
};

Accordion.defaultProps = {
  defaultExpanded: false,
  disabled: false,
  onStateChange: noop,
};

export { Accordion };

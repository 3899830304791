import PropTypes from 'prop-types';
import styled from 'styled-components';

import { cmsImagePropTypes } from '../../../../../prop-types';
import { Flex } from '../../../../grid';
import { Image } from '../../../../image';

const MaskBox = ({ item, index, activeIndex, displayOffset, isMobile, transitionDuration, ...rest }) => {
  const leftBorder = activeIndex - displayOffset;
  const rightBorder = activeIndex + displayOffset;
  let scale = 0.75;
  let opacity = 1;
  let position = {};
  let clipPath = '';
  if (index <= activeIndex - 1) {
    opacity = index >= leftBorder ? 0.5 : 0;
    position = { transform: 'translateX(-15%)' };
    clipPath = 'polygon(0 0, 100% 4%, 100% 96%, 4% 100%)';
  } else if (index === activeIndex) {
    if (!isMobile) scale = 1;
    clipPath = 'polygon(0 4%, 100% 0, 96% 100%, 0 92%)';
  } else if (index >= activeIndex + 1) {
    opacity = index <= rightBorder ? 0.5 : 0;
    position = { transform: 'translateX(15%)' };
    clipPath = 'polygon(0 4%, 100% 0, 96% 100%, 2% 96%)';
  }

  // The width and height here follow the design
  return (
    <StyledSlideBox
      position="relative"
      zIndex="-1"
      {...position}
      scale={scale}
      opacity={opacity}
      justifyContent="center"
      transitionDuration={transitionDuration}
      {...rest}
      width={{ _: '280px', xxl: '405px' }}
      height={{ _: '245px', xxl: '370px' }}
      margin="auto"
    >
      <StyledImage
        clipPath={clipPath}
        src={item.image.url}
        alt={item.image.alt}
        draggable="false"
        sizes="(max-width: 1414px) 280px, 405px"
        transitionDuration={transitionDuration}
      />
    </StyledSlideBox>
  );
};

const StyledSlideBox = styled(Flex)`
  transition: all ${({ transitionDuration }) => transitionDuration}s ease-in-out;
  scale: ${({ scale }) => scale};
  opacity: ${({ opacity }) => opacity};
`;

const StyledImage = styled(Image)`
  transition: all ${({ transitionDuration }) => transitionDuration}s ease-in-out;
  clip-path: ${({ clipPath }) => clipPath};
  object-fit: cover;
`;

MaskBox.propTypes = {
  item: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    image: cmsImagePropTypes,
    genericLink: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  displayOffset: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
  transitionDuration: PropTypes.number,
};

MaskBox.defaultProps = {
  isMobile: false,
  transitionDuration: 0.4,
};

export { MaskBox };

import { get, mapKeys } from 'lodash';

import { imageTransform } from '../../content/transforms';

const getHasStock = variants => {
  return variants.filter(variant => variant && (variant.stock === null || variant.stock > 0)).length > 0;
};

const formatPerksShopProducts = perksShopProducts => {
  return perksShopProducts
    .map(({ image, title, variants, perksShopProductCategory, ...rest }) => ({
      category: get(perksShopProductCategory, '[0].title'),
      hasStock: getHasStock(variants),
      heading: title,
      image: imageTransform(image),
      name: title,
      variants,
      ...rest,
    }))
    .filter(({ plu, variants }) => !!plu || (variants.length > 0 && variants.every(variant => !!variant.plu)));
};

const getItemLabelWithVariantTypes = (itemName, selectedVariant) => {
  let itemLabel = itemName;
  if (selectedVariant) {
    mapKeys(selectedVariant, (value, key) => {
      if (key !== 'plu' && key !== 'stock' && selectedVariant[key]) {
        itemLabel += ` ${value.toString()} `;
      }
    });
  }
  return itemLabel;
};

export { formatPerksShopProducts, getItemLabelWithVariantTypes };

import { FRAME_COLOR_VARIANTS as colorTable, FRAME_THEME_COLOR } from '@nandosaus/constants';
import { variant as styleVariant } from 'styled-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledSystemPropTypes from '@styled-system/prop-types';

import { Box, Flex } from '../../grid';

const MODAL_URL = '/static/images/frame/background.svg';
const { PINK, SALMON, YELLOW, GREEN, ORANGE, TEAL } = FRAME_THEME_COLOR;
const BackgroundFrame = ({
  children,
  showPepperIcon,
  showFireIcon,
  showTopBar,
  showBottomBar,
  variant,
  padding,
  tiltOffset,
  ...rest
}) => {
  // vw means change with the width of the viewport, so that the tilt is consistent when resizing
  const clipPath = `
        clip-path: polygon(0 calc(0% + ${
          showTopBar ? tiltOffset : '0vw'
        }), 100% 0, 100% calc(100% - ${tiltOffset}), 0% 100%);
    `;

  return (
    <Box {...rest} data-testid="background-frame">
      {showPepperIcon && (
        <StyledPepperSvg viewBox="0 0 160 171" variant={variant} aria-labelledby="pepper-flower">
          <use href={`${MODAL_URL}#pepper`} />
        </StyledPepperSvg>
      )}
      <StyledBox variant={variant} clipPath={clipPath}>
        {showFireIcon && (
          <StyledFireIconSvg viewBox="0 0 356 916" variant={variant} arial-labelledby="flame-symbol">
            <use href={`${MODAL_URL}#flame`} />
          </StyledFireIconSvg>
        )}
        <StyledChildren padding={padding}>{children}</StyledChildren>
        {showBottomBar && (
          <StyledBottomBarSvg viewBox="0 0 1440 101" variant={variant} aria-labelledby="bottom-colourful-bar">
            <use href={`${MODAL_URL}#bottom-bar`} />
          </StyledBottomBarSvg>
        )}
      </StyledBox>
    </Box>
  );
};

const StyledChildren = styled(Flex)`
  position: relative;
  padding: ${props => props.padding};
  z-index: 10;
`;

const StyledBox = styled(Box)(({ clipPath }) => {
  return [
    {
      position: 'relative',
    },
    clipPath,
    styleVariant({
      variants: {
        pink: {
          backgroundColor: colorTable.pink.container,
        },
        salmon: {
          backgroundColor: colorTable.salmon.container,
        },
        green: {
          backgroundColor: colorTable.green.container,
        },
        orange: {
          backgroundColor: colorTable.orange.container,
        },
        yellow: {
          backgroundColor: colorTable.yellow.container,
        },
        teal: {
          backgroundColor: colorTable.teal.container,
        },
      },
    }),
  ];
});

const StyledPepperSvg = styled.svg(
  {
    position: 'relative',
    width: '25%',
    left: '10px',
    transform: 'translateY(-50%)',
    marginBottom: '-100%',
  },
  styleVariant({
    variants: {
      pink: {
        fill: colorTable.pink.pepper,
      },
      salmon: {
        fill: colorTable.salmon.pepper,
      },
      green: {
        fill: colorTable.green.pepper,
      },
      orange: {
        fill: colorTable.orange.pepper,
      },
      yellow: {
        fill: colorTable.yellow.pepper,
      },
      teal: {
        fill: colorTable.teal.pepper,
      },
    },
  })
);

const StyledBottomBarSvg = styled.svg(
  {
    backgroundColor: 'transparent',
  },
  styleVariant({
    variants: {
      pink: {
        fill: colorTable.pink.bottomBar,
      },
      salmon: {
        fill: colorTable.salmon.bottomBar,
      },
      green: {
        fill: colorTable.green.bottomBar,
      },
      orange: {
        fill: colorTable.orange.bottomBar,
      },
      yellow: {
        fill: colorTable.yellow.bottomBar,
      },
      teal: {
        fill: colorTable.teal.bottomBar,
      },
    },
  })
);

const StyledFireIconSvg = styled.svg(
  {
    position: 'absolute',
    height: '50%',
    maxWidth: '50%',
    top: '25%',
    left: '0%',
  },
  styleVariant({
    variants: {
      pink: {
        fill: colorTable.pink.fireSymbol,
      },
      salmon: {
        fill: colorTable.salmon.fireSymbol,
      },
      green: {
        fill: colorTable.green.fireSymbol,
      },
      orange: {
        fill: colorTable.orange.fireSymbol,
      },
      yellow: {
        fill: colorTable.yellow.fireSymbol,
      },
      teal: {
        fill: colorTable.teal.fireSymbol,
      },
    },
  })
);

BackgroundFrame.propTypes = {
  children: PropTypes.node.isRequired,
  showPepperIcon: PropTypes.bool,
  showFireIcon: PropTypes.bool,
  showTopBar: PropTypes.bool,
  showBottomBar: PropTypes.bool,
  variant: PropTypes.oneOf([PINK, SALMON, YELLOW, GREEN, ORANGE, TEAL]),
  padding: StyledSystemPropTypes.space.padding,
  tiltOffset: PropTypes.string,
};

BackgroundFrame.defaultProps = {
  showPepperIcon: false,
  variant: PINK,
  showFireIcon: false,
  showTopBar: true,
  showBottomBar: true,
  padding: '2rem',
  tiltOffset: '5vw',
};
export { BackgroundFrame };

import { get, isDate, isEmpty, pickBy } from 'lodash';
import gql from 'graphql-tag';

import { getApiClient } from '../../../lib/api-client';

const submitMutation = gql`
  mutation submitEnquiry($data: EnquiryData!, $recaptchaToken: String!) {
    submitEnquiry(data: $data, recaptchaToken: $recaptchaToken) {
      errorMessage
      success
    }
  }
`;

const submitEnquiry = async (enquiryData, recaptchaToken) => {
  const sanitizedData = pickBy(enquiryData, value => isDate(value) || !isEmpty(value));

  try {
    const client = await getApiClient();
    const { data } = await client.mutate({
      mutation: submitMutation,
      variables: { data: sanitizedData, recaptchaToken },
    });

    return data.submitEnquiry;
  } catch (error) {
    return { success: false, errorMessage: get(error, 'errors[0].message', error.message) };
  }
};

export { submitEnquiry };

import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { cmsImagePropTypes } from '../../prop-types';
import { Col, Container, Row } from '../grid';
import { H2, P, RichText } from '../typography';
import { Image } from '../image';
import { imageTransform } from '../../content/transforms';
import { matrixBlockQuery, valuesItemsQuery } from '../../content/query-snippets';

const ValuesBlock = ({ heading, body, items }) => (
  <Container mt="1rem" mb="4rem">
    {(heading || body) && (
      <Row mb={{ xs: 0, lg: '4rem' }} justifyContent="center">
        <Col width={{ xs: 1, sm: 8 / 12, md: 6 / 12 }} textAlign="center">
          {heading && <H2 mb={1}>{heading}</H2>}
          {body && <RichText keepMargins>{body}</RichText>}
        </Col>
      </Row>
    )}
    <Row justifyContent="space-around" flexDirection={{ xs: 'column', lg: 'row' }}>
      {map(items, ({ image, heading: itemHeading, description }) => {
        const transformedImage = imageTransform(image);
        return (
          <Col
            width={{ xs: 1, lg: 3 / 12 }}
            textAlign={{ xs: 'center', sm: 'left', lg: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row', lg: 'column' }}
            display="flex"
            mt={{ xs: '4rem', lg: 0 }}
          >
            <Col
              mb={{ xs: '2rem', sm: 0, lg: '2rem' }}
              width={{ xs: 8 / 12, sm: 3 / 12, lg: 1 }}
              offset={[2 / 12, 0, 1 / 12, 0]}
              display="flex"
              alignItems="center"
            >
              <Image
                alt={transformedImage.alt}
                focalPoint={transformedImage.focalPoint}
                src={transformedImage.url}
                srcSet={transformedImage.srcset}
              />
            </Col>
            <Col width={{ xs: 1, sm: 8 / 12, md: 6 / 12, lg: 1 }} offset={[0, 1 / 12, 1 / 12, 0]}>
              <P fontWeight="bold" mb="1rem">
                {itemHeading}
              </P>
              <P>{description}</P>
            </Col>
          </Col>
        );
      })}
    </Row>
  </Container>
);

ValuesBlock.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.oneOfType([cmsImagePropTypes, PropTypes.arrayOf(cmsImagePropTypes)]),
    })
  ),
};

ValuesBlock.defaultProps = {
  heading: '',
  body: '',
  items: [{}],
};

ValuesBlock.query = `
  ... on contentMatrix_valuesBlock_BlockType {
    ${matrixBlockQuery}
    heading
    body
    ${valuesItemsQuery}
  }
`;

export { ValuesBlock };

import { isNil } from 'lodash';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../../grid';
import { getDragDirection } from './utils/get-drag-direction';

// calcuate by activeIndex and displayItemNumber. activeIndex is the index of the center item, all the calculation is based on it.
const Draggable = ({
  width,
  items,
  displayItemNumber,
  activeIndex,
  isMobile,
  ItemComponent,
  onDragEnd,
  isDragDisabled,
  xDragMargin,
  transitionDuration,
  ...rest
}) => {
  const displayOffset = Math.ceil((displayItemNumber - 1) / 2);
  const sceenWidthPercentage = 100 / displayItemNumber;
  const slideStyle = `
      transform: translateX(${(items.length > 1 ? activeIndex - displayOffset : 0) * sceenWidthPercentage * -1}%);
      `;

  return (
    <motion.div
      drag={isDragDisabled ? null : 'x'}
      onDragEnd={(event, info) => {
        const dragDirection = getDragDirection({ offset: info.offset, xDragMargin });
        return isNil(dragDirection) ? null : onDragEnd(dragDirection);
      }}
      dragConstraints={{ left: 0, right: 0 }}
      whileTap={{ cursor: 'grabbing' }}
      {...rest}
    >
      <DragBox
        // width here is the percentage of the container width that the whole Draggable component should take
        width={width}
        mt={{ xs: 0, md: 2 }}
        flexDirection="row"
        slideStyle={slideStyle}
        transitionDuration={transitionDuration}
      >
        {items.map((item, index) => {
          return (
            // minWidth here is the percentage of the StyledImageFlex width that each item should take
            <Box position="relative" key={item?.key || item?.id || item.heading} minWidth={`${sceenWidthPercentage}%`}>
              <ItemComponent
                // pass in to support component development
                index={index}
                // central index
                activeIndex={activeIndex}
                // how many items left or right of the central item
                displayOffset={displayOffset}
                isMobile={isMobile}
                item={item}
                transitionDuration={transitionDuration}
              />
            </Box>
          );
        })}
      </DragBox>
    </motion.div>
  );
};

const DragBox = styled(Flex)`
  transition: all ${({ transitionDuration }) => transitionDuration}s ease-in-out;
  ${({ slideStyle }) => slideStyle};
`;

Draggable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  displayItemNumber: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
  ItemComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]).isRequired,
  onDragEnd: PropTypes.func.isRequired,
  width: PropTypes.string,
  isDragDisabled: PropTypes.bool,
  xDragMargin: PropTypes.number,
  transitionDuration: PropTypes.number,
};

Draggable.defaultProps = {
  width: '100%',
  isMobile: false,
  isDragDisabled: false,
  xDragMargin: 50,
  transitionDuration: 0.4,
};
export { Draggable };

import { observer } from 'mobx-react';
import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { Col, Container, Flex, Row } from '../grid';
import { Icon } from '../icons';
import { useIsMinWidth } from '../../hooks/use-is-min-width';

const Carousel = ({ items, renderItem }) => {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const visibleItemsCount = !useIsMinWidth('xl') ? 2 : 3;

  if (isEmpty(items)) {
    return null;
  }

  const disableShowLess = visibleIndex === 0;
  const disableShowMore = visibleIndex + visibleItemsCount >= items.length;

  return (
    <Container>
      <Row alignItems="center">
        <Col width={{ _: 1 / 12 }}>
          <Flex justifyContent="flex-end">
            <button
              aria-label="show less"
              type="button"
              disabled={disableShowLess}
              onClick={() => setVisibleIndex(visibleIndex - visibleItemsCount)}
            >
              <Icon
                name="chevron"
                rotate="90deg"
                fill={disableShowLess ? 'grey300' : undefined}
                width="36px"
                height="36px"
              />
            </button>
          </Flex>
        </Col>
        <Col width={{ _: 10 / 12 }}>
          <Row>
            {items.slice(visibleIndex, visibleIndex + visibleItemsCount).map(item => (
              <Col width={{ _: 6 / 12, xl: 4 / 12 }} key={item.id}>
                {renderItem(item)}
              </Col>
            ))}
          </Row>
        </Col>
        <Col width={{ _: 1 / 12 }}>
          <button
            aria-label="show more"
            type="button"
            disabled={disableShowMore}
            onClick={() => setVisibleIndex(visibleIndex + visibleItemsCount)}
          >
            <Icon
              name="chevron"
              rotate="270deg"
              fill={disableShowMore ? 'grey300' : undefined}
              width="36px"
              height="36px"
            />
          </button>
        </Col>
      </Row>
    </Container>
  );
};

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderItem: PropTypes.func.isRequired,
};

const ObservedCarousel = observer(Carousel);
export { ObservedCarousel as Carousel };

import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { Alert } from '../alert';
import { Box, Col, Container, Row } from '../grid';
import { EnquiryFormFields } from './enquiry-form-fields';
import { Form } from '../form';
import { matrixBlockQuery } from '../../content/query-snippets';
import { submitEnquiry } from '../../utils/enquiries/submit-enquiry';
import { useSchema } from '../../hooks/use-schema';

const EnquiryFormBlock = observer(() => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState();
  const validationSchema = useSchema('enquiry');

  return (
    <Container>
      <Row>
        <Col width={{ xs: 12 / 12, lg: 6 / 12 }} offset={[0, 0, 0, 3 / 12]}>
          <Form
            buttonText="Submit"
            error={error}
            initialValues={{}}
            onSubmit={async (enquiryData, formik) => {
              setError(undefined);
              setSubmitted(false);

              const recaptchaToken = await executeRecaptcha('enquiry_form');

              let response;

              try {
                response = await submitEnquiry(enquiryData, recaptchaToken);
              } catch (submissionError) {
                setError(submissionError.message);
              }

              if (response.success) {
                setSubmitted(true);
                formik.resetForm();
              } else {
                setError(response.errorMessage);
              }
            }}
            validationSchema={validationSchema}
          >
            <EnquiryFormFields />
            {submitted && (
              <Box mb={1}>
                <Alert type="success">Your enquiry has been sent. We will get back to you as soon as possible.</Alert>
              </Box>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
});

const ReCaptchaEnquiryFormBlock = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.recaptcha.siteKeyV3}>
      <EnquiryFormBlock />
    </GoogleReCaptchaProvider>
  );
};

ReCaptchaEnquiryFormBlock.query = `
  ... on contentMatrix_enquiryFormBlock_BlockType {
	  ${matrixBlockQuery}
  }
`;

export { ReCaptchaEnquiryFormBlock as EnquiryFormBlock };

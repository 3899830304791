import { head, isEmpty, last } from 'lodash';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getDistanceBetweenElements } from '../../utils/get-distance-between-elements';

const DISTANCE_OFFSET = -10;

const useWindowSize = dotRef => {
  const [distance, setDistance] = useState();

  useEffect(() => {
    const handleResize = () => {
      if (dotRef.current.length > 1) {
        setDistance(getDistanceBetweenElements(head(dotRef.current), last(dotRef.current)) + DISTANCE_OFFSET);
      } else {
        setDistance(0);
      }
    };

    if (isEmpty(distance)) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [distance, dotRef]);

  return distance;
};

useWindowSize.propTypes = {
  dotRef: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.node),
  }).isRequired,
};

export { useWindowSize };

import React from 'react';

import { EnquiryFormFieldWrapper } from '../enquiry-form-field-wrapper';
import { FormikDateTimeInput, FormikSelectInput } from '../../input';
import { useRestaurants } from '../../../hooks/use-restaurants';

const RestaurantFeedbackFields = () => {
  const [restaurants, loadingRestaurants] = useRestaurants();

  return (
    <>
      <EnquiryFormFieldWrapper>
        <FormikSelectInput
          disabled={loadingRestaurants}
          label="Restaurant"
          name="restaurant"
          options={restaurants
            .map(restaurant => `${restaurant.address.suburb} - ${restaurant.name}`)
            .sort()
            .map(restaurant => ({
              text: restaurant,
              value: restaurant,
            }))}
          placeholder={loadingRestaurants ? 'Loading restaurants...' : 'Select topic'}
        />
      </EnquiryFormFieldWrapper>
      <EnquiryFormFieldWrapper>
        <FormikSelectInput
          label="Type of feedback"
          name="feedbackType"
          options={[
            {
              text: 'Compliments',
              value: 'Compliments',
            },
            {
              text: 'Customer service',
              value: 'Customer service',
            },
            {
              text: 'Product quality',
              value: 'Product quality',
            },
            {
              text: 'Restaurant maintenance',
              value: 'Restaurant maintenance',
            },
            {
              text: 'Other',
              value: 'Other',
            },
          ]}
          placeholder="Select topic"
        />
      </EnquiryFormFieldWrapper>
      <EnquiryFormFieldWrapper>
        <FormikSelectInput
          label="Type of order"
          name="orderType"
          options={[
            {
              text: 'Delivery',
              value: 'Delivery',
            },
            {
              text: 'Dine-in',
              value: 'Dine-in',
            },
            {
              text: 'Takeaway',
              value: 'Takeaway',
            },
          ]}
          placeholder="Select topic"
        />
      </EnquiryFormFieldWrapper>
      <EnquiryFormFieldWrapper>
        <FormikDateTimeInput
          enableTime
          format="DD/MM/YYYY @ HH:mm"
          label="Date of visit"
          name="dateOfVisit"
          placeholder="DD/MM/YYYY"
        />
      </EnquiryFormFieldWrapper>
    </>
  );
};

export { RestaurantFeedbackFields };

import { DATE_TIME_FORMATS } from '@nandosaus/constants';
import { PropTypes } from 'prop-types';
import dayjs from 'dayjs';
import React from 'react';

import { Box, Container } from '../grid';
import { cmsImagesPropTypes } from '../../prop-types';
import { H1, Subheading } from '../typography';
import { imageTransform } from '../../content/transforms';
import { matrixBlockQuery, mosaicBlockQuery } from '../../content/query-snippets';
import { MosaicItem } from './mosaic-item';

const getTypeDetail = entry => {
  if (entry.typeHandle !== 'news' || !entry.date) return null;

  return dayjs(entry.date).format(DATE_TIME_FORMATS.DATE_DAY_MONTH);
};

const MosaicBlock = ({ heading, subheading, mosaicEntries }) => {
  const items = mosaicEntries.map(entry => ({
    detail: getTypeDetail(entry),
    heading: entry.title,
    href: `/${entry.uri}`,
    image: imageTransform(entry.image),
  }));

  return (
    <Container my={2}>
      <Box
        display="grid"
        gridAutoRows={{ lg: '6rem', xxl: '5rem' }}
        gridColumnGap={1}
        gridTemplateColumns={{ _: 'repeat(2, 1fr)', lg: 'repeat(10, 1fr)' }}
        justifyContent="center"
      >
        <Box
          gridColumn={{ _: '1 / 3', lg: '1 / 5' }}
          gridRow={{ lg: '1 / 4' }}
          textAlign="center"
          width={1}
          mb={{ xs: 2, md: 0 }}
        >
          <H1 mb={{ _: '1rem', md: '0.5rem', xl: '1.5rem' }}>{heading}</H1>
          {subheading && <Subheading>{subheading}</Subheading>}
        </Box>
        {items.map((item, index) => (
          <MosaicItem key={item.heading} item={item} index={index} />
        ))}
      </Box>
    </Container>
  );
};

MosaicBlock.propTypes = {
  heading: PropTypes.string.isRequired,
  mosaicEntries: PropTypes.arrayOf(PropTypes.shape({ image: cmsImagesPropTypes, title: PropTypes.string })),
  subheading: PropTypes.string,
};

MosaicBlock.defaultProps = {
  mosaicEntries: [],
  subheading: undefined,
};

MosaicBlock.query = `
  ... on contentMatrix_mosaicBlock_BlockType {
    ${matrixBlockQuery}
    heading
    subheading
    ${mosaicBlockQuery}
  }
`;

export { MosaicBlock };

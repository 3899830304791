import { getSchema } from '@nandosaus/constants';

import { useMarket } from '../use-market';

const useSchema = schema => {
  const market = useMarket();

  return getSchema(schema, market);
};

export { useSchema };

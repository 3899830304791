import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { compact, get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { useRootStore } from '@nandosaus/state-management';
import { useRouter } from 'next/router';
import gql from 'graphql-tag';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { analytics } from '../../analytics';
import { Box, Col, Container, Flex, Row } from '../grid';
import { Carousel } from '../carousel';
import { fetch } from '../../content/queries';
import { formatPerksShopProducts } from '../../utils/perks-store-data';
import { H2, P } from '../typography';
import { Link } from '../link';
import { LoadingIndicator } from '../icons/loading-indicator';
import {
  matrixBlockQuery,
  perksShopHighlightReelItemsQuery,
  perksShopProductsQuery,
} from '../../content/query-snippets';
import { navigation } from '../../constants';
import { perksShopProductArrayPropTypes } from '../../prop-types';
import { ProductCard } from '../card';
import { useIsMinWidth } from '../../hooks/use-is-min-width';

const LIST_TYPES = {
  SCROLL: 'default',
  CAROUSEL: 'carousel',
};

const PerksShopHighlightReelBlock = ({ perksShopHighlightReelHeading, perksShopHighlightReelItems }) => {
  const { MemberStore, PerksShopStore } = useRootStore();
  const { loading: isProfileLoading, isSignedIn, profile } = MemberStore;
  const router = useRouter();
  const type = useIsMinWidth('lg') ? LIST_TYPES.CAROUSEL : LIST_TYPES.SCROLL;
  const [items, setItems] = useState(null);
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const loading = isProfileLoading || isProductsLoading;

  const fetchProductFromCMS = useCallback(async () => {
    const productsQuery = gql`
      ${perksShopProductsQuery}
    `;

    const data = await fetch({ query: productsQuery });

    const products = get(data, 'perksShopProductData.perksShopProducts', []);

    const perksShopProducts = compact(
      perksShopHighlightReelItems.map(highlightReelItem => {
        const product = products.find(item => item.id === highlightReelItem.id);
        if (isEmpty(product)) {
          return null;
        }
        return product;
      })
    );
    return perksShopProducts;
  }, [perksShopHighlightReelItems]);

  useEffect(() => {
    const fetchPerksShopProductsFromId = async () => {
      setItems(formatPerksShopProducts(await fetchProductFromCMS()));
    };
    fetchPerksShopProductsFromId().finally(() => {
      setIsProductsLoading(false);
    });
  }, [fetchProductFromCMS, perksShopHighlightReelItems]);

  let headingContent = null;

  if (perksShopHighlightReelHeading) {
    headingContent = <H2 mb={1}>{perksShopHighlightReelHeading}</H2>;
  } else {
    headingContent = isSignedIn ? (
      <H2 mb={1}>
        {get(profile, 'givenName')}, you now have <br />{' '}
        <PointsHighlight>{get(profile, 'balances.points')} points</PointsHighlight> to spend
      </H2>
    ) : (
      <H2 mb={1}>Checkout our latest merch</H2>
    );
  }

  const headingSubText = (
    <P>
      Visit the{' '}
      <Link href={navigation.PERKS_SHOP} variant={1}>
        PERi-Perks store{' '}
      </Link>
      {isSignedIn
        ? `and turn your ${get(profile, 'balances.points')} points into Nando’s merch`
        : `and turn your points into Nando’s merch`}
    </P>
  );

  const viewProduct = useCallback(
    product => {
      PerksShopStore.setSelectedItem({ product: product.id });
      if (router.pathname !== navigation.PERKS_SHOP) {
        router.push(
          {
            pathname: navigation.PERKS_SHOP,
          },
          navigation.PERKS_SHOP
        );
      }

      analytics.track(ANALYTICS_EVENTS.PRODUCT_CLICKED, {
        id: product.id,
        name: product.name,
        category: product.category,
      });
    },
    [router, PerksShopStore]
  );

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        items &&
        items.length > 0 && (
          <Flex justifyContent="center" bg="backgroundDark" py={3}>
            <Container justifyContent="center">
              <Row justifyContent="center" pb={2}>
                <Col textAlign="center">
                  <Flex justifyContent="center" flexDirection="column">
                    {headingContent}
                    {!perksShopHighlightReelHeading && headingSubText}
                  </Flex>
                </Col>
              </Row>
              {type === LIST_TYPES.CAROUSEL ? (
                <Carousel
                  items={items}
                  renderItem={product => (
                    <ProductCard
                      item={{
                        ...product,
                        image: product.image,
                      }}
                      onClick={() => viewProduct(product)}
                    />
                  )}
                />
              ) : (
                <Wrapper>
                  {items.map(product => (
                    <ScrollingCard mr={1} key={product.id} flexShrink="0" width="260px">
                      <ProductCard
                        item={{
                          ...product,
                          image: product.image,
                        }}
                        onClick={() => viewProduct(product)}
                      />
                    </ScrollingCard>
                  ))}
                </Wrapper>
              )}
            </Container>
          </Flex>
        )
      )}
    </>
  );
};

const Wrapper = styled(Flex)`
  overflow-x: auto;
`;

const ScrollingCard = styled(Box)`
  :first-child {
    margin-left: 1.5rem;
  }

  :last-child {
    margin-right: 1.5rem;
  }
`;

const PointsHighlight = styled.span`
  color: ${({ theme }) => theme.colors.periRed};
`;

PerksShopHighlightReelBlock.query = `
  ... on contentMatrix_perksShopHighlightReelBlock_BlockType {
    perksShopHighlightReelHeading
    ${matrixBlockQuery}
    ${perksShopHighlightReelItemsQuery}
  }
`;

PerksShopHighlightReelBlock.propTypes = {
  perksShopHighlightReelHeading: PropTypes.string,
  perksShopHighlightReelItems: perksShopProductArrayPropTypes.isRequired,
};

PerksShopHighlightReelBlock.defaultProps = {
  perksShopHighlightReelHeading: null,
};

const PerksShopHighlightReelBlockObserver = observer(PerksShopHighlightReelBlock);
export { PerksShopHighlightReelBlockObserver as PerksShopHighlightReelBlock };

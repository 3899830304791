import { getPositionAtCenter } from '../get-position-at-centre';

const getDistanceBetweenElements = (element1, element2) => {
  const aPos = getPositionAtCenter(element1);
  const bPos = getPositionAtCenter(element2);

  return Math.sqrt((aPos.x - bPos.x) ** 2 + (aPos.y - bPos.y) ** 2);
};

export { getDistanceBetweenElements };

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  padding-bottom: ${props => props.aspectRatio}%;
  video,
  iframe {
    border: none;
    height: auto;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    position: absolute;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &:-webkit-full-screen {
      transform: unset;
    }
`;

export { Wrapper };

import { ENQUIRY_TOPICS } from '@nandosaus/constants';
import { useFormikContext } from 'formik';
import { values } from 'lodash';
import React from 'react';

import { Divider } from '../../divider';
import { EnquiryFormFieldWrapper } from '../enquiry-form-field-wrapper';
import { FormikSelectInput } from '../../input';
import { P } from '../../typography';
import { RestaurantFeedbackFields } from '../restaurant-feedback-fields';
import { SimpleEnquiryFields } from '../simple-enquiry-fields';

const EnquiryFormFields = () => {
  const formikContext = useFormikContext();

  return (
    <>
      <P fontWeight="bold">Your Enquiry</P>
      <Divider mt="0.5rem" mb={1} />
      <EnquiryFormFieldWrapper>
        <FormikSelectInput
          name="topic"
          label="What can we help you with?"
          options={values(ENQUIRY_TOPICS).map(topic => ({
            text: topic,
            value: topic,
          }))}
          placeholder="Select topic"
        />
      </EnquiryFormFieldWrapper>
      {formikContext.values.topic === ENQUIRY_TOPICS.RESTAURANT_FEEDBACK && <RestaurantFeedbackFields />}
      <SimpleEnquiryFields />
    </>
  );
};

export { EnquiryFormFields };

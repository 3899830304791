import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../button';
import { buttonPropTypes, cmsImagesPropTypes } from '../../prop-types';
import { buttonQuery, matrixBlockQuery, relatedEntriesQuery } from '../../content/query-snippets';
import { buttonTransform, imageTransform } from '../../content/transforms';
import { Col, Container, Flex, Row } from '../grid';
import { HeadingWithLineHighlights } from '../heading-with-line-highlights';
import { NewsCard, ProductCard, RecipeCard } from '../card';

const cardOptions = {
  news: NewsCard,
  product: ProductCard,
  recipe: RecipeCard,
};

const RelatedEntriesBlock = ({ heading, relatedEntries, button, children }) => {
  const formattedButton = buttonTransform(button);

  return (
    <Container>
      {heading && <HeadingWithLineHighlights heading={heading} />}
      {children}
      <Row>
        <Col width={{ xs: 12 / 12, md: 10 / 12 }} offset={[0, 0, 1 / 12]}>
          <Row flexWrap="wrap">
            {relatedEntries.map(item => {
              const CardComponent = cardOptions[item.typeHandle];

              return (
                <Col py=".5rem" key={item.title} width={{ xs: 12 / 12, md: 4 / 12 }}>
                  <CardComponent
                    item={{
                      href: `/${item.uri}`,
                      heading: item.title,
                      ...item,
                      image: imageTransform(item.image),
                    }}
                  />
                </Col>
              );
            })}
          </Row>
          {!isEmpty(formattedButton) && (
            <Flex my={1} justifyContent="center">
              <Button {...formattedButton} variant="secondary">
                {formattedButton.text}
              </Button>
            </Flex>
          )}
        </Col>
      </Row>
    </Container>
  );
};

RelatedEntriesBlock.query = `
  ... on contentMatrix_relatedEntriesBlock_BlockType {
    ${buttonQuery}
    ${matrixBlockQuery}
    ${relatedEntriesQuery}
    heading
  }
`;

RelatedEntriesBlock.propTypes = {
  button: PropTypes.arrayOf(buttonPropTypes),
  heading: PropTypes.string.isRequired,
  relatedEntries: PropTypes.arrayOf(
    PropTypes.shape({
      typeHandle: PropTypes.string,
      image: cmsImagesPropTypes,
      heading: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.node,
};

RelatedEntriesBlock.defaultProps = {
  button: null,
  children: null,
};

export { RelatedEntriesBlock };

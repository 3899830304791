import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { assetQuery, matrixBlockQuery } from '../../content/query-snippets';
import { CardListing } from '../card-listing';
import { Col, Container, Row } from '../grid';
import { PageLinkCard } from '../card';
import { RichText, Subheading } from '../typography';

const PageListingBlock = ({ heading, body, pages }) => {
  const items = useMemo(
    () =>
      pages.map(page => ({
        image: page.gridImage,
        heading: page.title,
        description: page.shortDescription,
        link: page.uri,
      })),
    [pages]
  );
  return (
    <Container p={1}>
      <Row flexWrap="wrap">
        <Col mb={2} width={{ xs: 12 / 12, md: 8 / 12 }} offset={[0, 0, 2 / 12]} textAlign="center">
          {heading && <Subheading mb={1}>{heading}</Subheading>}
          {body && <RichText>{body}</RichText>}
        </Col>
        <Col width={{ xs: 12 / 12, md: 10 / 12 }} offset={[0, 0, 1 / 12]}>
          <CardListing
            items={items}
            CardComponent={PageLinkCard}
            cardWidth={{ xs: 12 / 12, sm: 6 / 12, lg: 4 / 12 }}
            imageTransformIsRequired
          />
        </Col>
      </Row>
    </Container>
  );
};

PageListingBlock.query = `
... on contentMatrix_pageListingBlock_BlockType {
  ${matrixBlockQuery}
  body
  heading
  pages {
    ... on pages_pages_Entry {
      ${assetQuery('gridImage')}
      title
      uri
      shortDescription
    }
  }
}
`;

PageListingBlock.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      gridImage: PropTypes.shape,
      title: PropTypes.string,
      uri: PropTypes.string,
      shortDescription: PropTypes.string,
    })
  ).isRequired,
};

PageListingBlock.defaultProps = {
  heading: undefined,
  body: undefined,
};

export { PageListingBlock };

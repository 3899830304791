import PropTypes from 'prop-types';
import React from 'react';

import { Col, Container, Row } from '../grid';
import { matrixBlockQuery } from '../../content/query-snippets';
import { RichText, Subheading } from '../typography';

const ContentBlock = ({ blockTitle, body }) => (
  <Container my={3}>
    <Row>
      <Col width={{ xs: 1, md: 8 / 12, lg: 6 / 12 }} offset={[0, 0, 2 / 12, 3 / 12]}>
        <Subheading mb=".5rem">{blockTitle}</Subheading>
        <RichText keepMargins>{body}</RichText>
      </Col>
    </Row>
  </Container>
);

ContentBlock.query = `
  ... on contentMatrix_contentBlock_BlockType {
    ${matrixBlockQuery}
    blockTitle
    body
  }
`;

ContentBlock.propTypes = {
  blockTitle: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export { ContentBlock };

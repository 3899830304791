import { INPUT_TYPE } from '@nandosaus/constants';
import React from 'react';

import { Divider } from '../../divider';
import { EnquiryFormFieldWrapper } from '../enquiry-form-field-wrapper';
import { FormikTextareaInput, FormikTextInput } from '../../input';
import { P } from '../../typography';

const SimpleEnquiryFields = () => (
  <>
    <EnquiryFormFieldWrapper>
      <FormikTextareaInput name="message" label="Message" />
    </EnquiryFormFieldWrapper>
    <P fontWeight="bold">Your Details</P>
    <Divider mt="0.5rem" mb={1} />
    <EnquiryFormFieldWrapper>
      <FormikTextInput name="givenName" label="First name" />
    </EnquiryFormFieldWrapper>
    <EnquiryFormFieldWrapper>
      <FormikTextInput name="familyName" label="Last name" />
    </EnquiryFormFieldWrapper>
    <EnquiryFormFieldWrapper>
      <FormikTextInput name="email" label="Email" type={INPUT_TYPE.EMAIL} />
    </EnquiryFormFieldWrapper>
    <EnquiryFormFieldWrapper>
      <FormikTextInput name="mobile" label="Mobile number" type={INPUT_TYPE.PHONE} />
    </EnquiryFormFieldWrapper>
  </>
);

export { SimpleEnquiryFields };

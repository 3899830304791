import { get, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { IframeEmbed } from './iframe-embed';
import { Preview } from './preview';
import { VideoElement } from './video-element';
import { Wrapper } from './wrapper';

const Video = ({ autoPlay, captions, height, mimeType, poster, src, title, width }) => {
  const [showVideo, setShowVideo] = useState(false);
  const aspectRatio = ((Math.round(height) / Math.round(width)) * 100).toFixed(2);
  const isIframe = isEqual(mimeType, 'text/html');
  const posterUrl = get(poster, 'url');
  const hasPoster = Boolean(posterUrl);
  const VideoComponent = isIframe ? IframeEmbed : VideoElement;

  const handleClick = () => {
    setShowVideo(true);
  };

  return (
    <Wrapper aspectRatio={aspectRatio}>
      {showVideo || autoPlay || !hasPoster ? (
        <VideoComponent
          autoPlay={showVideo || autoPlay}
          captions={captions}
          controls={!autoPlay}
          height={height}
          loop={autoPlay}
          muted={autoPlay}
          poster={posterUrl}
          src={src}
          title={title}
          width={width}
        />
      ) : (
        <Preview aspectRatio={aspectRatio} poster={poster} onClick={handleClick} />
      )}
    </Wrapper>
  );
};

Video.propTypes = {
  autoPlay: PropTypes.bool,
  captions: PropTypes.string,
  height: PropTypes.number,
  mimeType: PropTypes.string,
  poster: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string,
  }),
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
};

Video.defaultProps = {
  autoPlay: false,
  captions: null,
  height: 1080,
  mimeType: 'video/mp4',
  poster: null,
  title: null,
  width: 1920,
};

export { Video };

import PropTypes from 'prop-types';

const Dot = ({ className }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <circle cx="14" cy="14" r="9.5" fill="#FF971E" stroke="#FAF7F0" strokeWidth="5" />
    <circle cx="14" cy="14" r="12" stroke="#95E74F" strokeWidth="4" />
  </svg>
);

Dot.propTypes = {
  className: PropTypes.string,
};

Dot.defaultProps = {
  className: '',
};

export { Dot };

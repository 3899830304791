// Amount of play or slack in X and Y directions before the carousel registers a drag event. Prevents unintended carousel scroll.
const X_DRAG_MARGIN = 50;
const Y_DRAG_MARGIN = 300;

export const getDragDirection = ({ offset, xDragMargin = X_DRAG_MARGIN }) => {
  // Disregard any gesture that's predominately up/down. Always returns a negative value regardless of up/down movement.
  if (offset.y < -Y_DRAG_MARGIN) {
    return null;
  }
  if (offset.x < -xDragMargin) {
    return 'right';
  }
  if (offset.x > xDragMargin) {
    return 'left';
  }
  return null;
};

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from 'styled-components';

import { Box, Col, Container, Row } from '../grid';
import { breakpoints, headingSize } from '../../constants';
import { Button } from '../button';
import { buttonPropTypes, cmsImagePropTypes } from '../../prop-types';
import { buttonTransform, imageTransform } from '../../content/transforms';
import { careerOptionsItemsQuery, matrixBlockQuery } from '../../content/query-snippets';
import { CurvyLine } from './components/curvy-line';
import { Dot } from './components/dot';
import { H2, P, RichText } from '../typography';
import { Image } from '../image';
import { TiltedHeading } from '../tilted-heading';
import { TiltedTag } from '../tilted-tag';
import { useWindowSize } from './hooks/use-window-size';

const CareerOptionsBlock = ({ heading, body, items }) => {
  const dotRef = useRef([]);

  const distance = useWindowSize(dotRef);

  return (
    <Container>
      {(heading || body) && (
        <Row my="4rem" justifyContent="center">
          <Col width={{ xs: 8 / 12, md: 6 / 12 }} textAlign="center">
            {heading && <H2 mb={1}>{heading}</H2>}
            {body && <RichText keepMargins>{body}</RichText>}
          </Col>
        </Row>
      )}
      {items.map(({ image, heading: itemHeading, description, button, tagText }, index) => {
        const transformedButton = buttonTransform(button);
        const transformedImage = imageTransform(image);
        return (
          <Row mb="4rem">
            <Col
              width={{ xs: 1 / 12, md: 4 / 12 }}
              offset={[0, 1 / 12, 0]}
              position="relative"
              display="flex"
              alignItems="center"
            >
              <Box height="15rem" width="90%" display={{ xs: 'none', md: 'block' }}>
                <Image
                  alt={transformedImage.alt}
                  focalPoint={transformedImage.focalPoint}
                  src={transformedImage.url}
                  srcSet={transformedImage.srcset}
                  height="100%"
                  width="100%"
                  objectFit="cover"
                />
              </Box>
              <div
                ref={el => {
                  dotRef.current[index] = el;
                }}
              >
                <StyledDot />
              </div>
              {index === 0 && <StyledLine viewHeight={distance} />}
            </Col>
            <Col width={{ xs: 1, sm: 6 / 12 }} offset={[2 / 12, 2 / 12, 1 / 12]} pt="1rem" position="relative">
              <Box display="inline-block">
                <TiltedHeading fontSize={headingSize.LxMxS3}>{itemHeading}</TiltedHeading>
                {tagText && (
                  <TagContainer>
                    <TiltedTag>{tagText}</TiltedTag>
                  </TagContainer>
                )}
              </Box>
              <P my="1.5rem">{description}</P>
              {!isEmpty(transformedButton) && (
                <Button {...transformedButton} variant="secondary">
                  {transformedButton.text}
                </Button>
              )}
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};

const StyledDot = styled(Dot)`
  position: absolute;
  top: 20%;
  left: 105%;
  z-index: 10;
`;

const StyledLine = styled(CurvyLine)`
  position: absolute;
  top: 28%;
  left: 105%;
`;

const TagContainer = styled.div`
  position: absolute;
  left: 10rem;
  top: -10%;

  @media (min-width: ${breakpoints.md}) {
    left: 8rem;
  }

  @media (min-width: ${breakpoints.xxl}) {
    left: 6.5rem;
  }
`;

CareerOptionsBlock.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.oneOfType([cmsImagePropTypes, PropTypes.arrayOf(cmsImagePropTypes)]),
      button: PropTypes.oneOfType([buttonPropTypes, PropTypes.arrayOf(buttonPropTypes)]),
    })
  ),
};

CareerOptionsBlock.defaultProps = {
  heading: '',
  body: '',
  items: [{}],
};

CareerOptionsBlock.query = `
  ... on contentMatrix_careerOptionsBlock_BlockType {
    ${matrixBlockQuery}
    heading
    body
    ${careerOptionsItemsQuery}
  }
`;

export { CareerOptionsBlock };

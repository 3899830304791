import { PropTypes } from 'prop-types';
import IframeResizer from 'iframe-resizer-react';

import { Container } from '../grid';
import { matrixBlockQuery } from '../../content/query-snippets';

/**
 * IframeFormBlock
 *
 * This block uses https://github.com/davidjbradshaw/iframe-resizer indirectly.
 * So to make cross domain iframe page work the iframed page need to support that as well.
 *
 * @param {object} options
 * @param {String} options.blockTitle The title of the block.
 * @param {string} options.source  The source of the iframe.
 * @see: https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/troubleshooting.md#iframe-not-resizing
 */
const IframeFormBlock = ({ blockTitle, source }) => (
  <Container>
    <IframeResizer
      data-testid="iframe"
      autoResize
      title={blockTitle}
      src={source}
      style={{ width: '1px', minWidth: '100%', height: '100vh' }}
      scrolling
    />
  </Container>
);

IframeFormBlock.propTypes = {
  source: PropTypes.string.isRequired,
  blockTitle: PropTypes.string.isRequired,
};

IframeFormBlock.query = `
  ... on contentMatrix_iframeFormBlock_BlockType {
    ${matrixBlockQuery}
    blockTitle
    source
  }
`;

export { IframeFormBlock };

import { first } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { assetQuery, matrixBlockQuery } from '../../content/query-snippets';
import { cmsImagesPropTypes } from '../../prop-types';
import { Col, Container, Row } from '../grid';
import { H2, RichText } from '../typography';
import { imageTransform } from '../../content/transforms';
import { Video } from '../video';

const VideoContentBlock = ({ heading, body, video }) => {
  const { autoPlay, isIframeVideo, poster, source, title } = first(video);

  return (
    <Container>
      {(heading || body) && (
        <Row my={2} justifyContent="center">
          <Col width={{ xs: 8 / 12, md: 6 / 12 }} textAlign="center">
            {heading && <H2 mb={1}>{heading}</H2>}
            {body && <RichText keepMargins>{body}</RichText>}
          </Col>
        </Row>
      )}
      <Row>
        <Col width={{ xs: 12 / 12, md: 8 / 12 }} offset={[0, 0, 2 / 12]}>
          <Video
            autoPlay={autoPlay}
            mimeType={isIframeVideo ? 'text/html' : 'video/mp4'}
            poster={poster ? imageTransform(poster) : null}
            src={source}
            title={title}
          />
        </Col>
      </Row>
    </Container>
  );
};

VideoContentBlock.propTypes = {
  body: PropTypes.string,
  heading: PropTypes.string,
  video: PropTypes.arrayOf(
    PropTypes.shape({
      autoPlay: PropTypes.bool,
      isIframeVideo: PropTypes.bool,
      poster: cmsImagesPropTypes,
      source: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
};

VideoContentBlock.defaultProps = {
  body: null,
  heading: null,
};

VideoContentBlock.query = `
	... on contentMatrix_videoContentBlock_BlockType {
		${matrixBlockQuery}
		body
		heading
		video {
			... on videos_videos_Entry {
				${assetQuery('poster')}
				title
        autoPlay
        isIframeVideo
        source
			}
		}
	}
`;

export { VideoContentBlock };

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from '../../icons';
import { Image } from '../../image';

const PlayButton = styled.button`
  align-items: center;
  background-color: rgba(33, 33, 33, 0);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  transition: background-color 260ms cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
  &:hover,
  &:active {
    background-color: rgba(33, 33, 33, 0.2);
  }
`;

const StyledIcon = styled(Icon)`
  circle {
    fill: ${({ theme }) => theme.colors.greyPrimary};
  }
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const Preview = ({ poster, onClick }) => {
  return (
    <>
      <Image src={poster.url} srcSet={poster.srcset} alt={poster.alt} isBackground />
      <PlayButton type="button" onClick={() => onClick()}>
        <StyledIcon name="playButton" width="5rem" height="5rem" />
      </PlayButton>
      ;
    </>
  );
};

Preview.propTypes = {
  onClick: PropTypes.func.isRequired,
  poster: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string.isRequired,
    srcset: PropTypes.string,
  }).isRequired,
};

export { Preview };

import { getApiClient } from '../../lib/api-client';

/**
 * Fetches data from the GraphQL API
 * @param object options
 * @param string options.query - GraphQL query
 * @param object options.variables - Variables to replace placeholders in the query
 * @param object options.headers - Additional headers
 * @returns promise
 */
const fetch = async ({ query, variables = {}, headers }) => {
  const client = await getApiClient();
  const { data } = await client.query({ headers, query, variables });
  return data;
};

export { fetch };

import { first, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { assetQuery, matrixBlockQuery } from '../../content/query-snippets';
import { CardListing } from '../card-listing';
import { cmsImagePropTypes } from '../../prop-types';
import { Col, Container, Row } from '../grid';
import { FeatureCard } from '../card';
import { H2, RichText } from '../typography';

const columns = {
  '2': {
    cardWidth: { xs: 12 / 12, md: 6 / 12 },
    tableWidth: { xs: 12 / 12, lg: 10 / 12 },
    tableOffset: [0, 0, 0, 1 / 12],
  },
  '3': {
    cardWidth: { xs: 12 / 12, md: 4 / 12 },
    tableWidth: { xs: 12 / 12 },
    tableOffset: [0],
  },
  '4': {
    cardWidth: { xs: 12 / 12, md: 3 / 12 },
    tableWidth: { xs: 12 / 12 },
    tableOffset: [0],
  },
};

const FeatureCardBlock = ({ backgroundColor, heading, body, featureCardItems, cardsPerRow }) => {
  const transformedFeatureCardItems = featureCardItems.map(featureCardItem => {
    const cardLink = get(first(featureCardItem.internalLink), 'uri');
    return {
      ...featureCardItem,
      internalLink: [{ uri: cardLink ? `/${cardLink}` : null }],
    };
  });

  return (
    <Container my={2}>
      {(heading || body) && (
        <Row my={2} justifyContent="center">
          <Col width={{ xs: 8 / 12, md: 6 / 12 }} textAlign="center">
            {heading && <H2 mb={1}>{heading}</H2>}
            {body && <RichText keepMargins>{body}</RichText>}
          </Col>
        </Row>
      )}
      {!isEmpty(featureCardItems) && (
        <Row>
          <Col width={columns[cardsPerRow].tableWidth} offset={columns[cardsPerRow].tableOffset}>
            <CardListing
              cardBackgroundColor={backgroundColor}
              body={body}
              cardAlignment="center"
              CardComponent={FeatureCard}
              cardWidth={columns[cardsPerRow].cardWidth}
              heading={heading}
              imageTransformIsRequired
              items={transformedFeatureCardItems}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

FeatureCardBlock.propTypes = {
  backgroundColor: PropTypes.oneOf(['light', 'dark']),
  body: PropTypes.string,
  heading: PropTypes.string,
  featureCardItems: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      heading: PropTypes.string,
      image: PropTypes.arrayOf(cmsImagePropTypes),
    })
  ).isRequired,
  cardsPerRow: PropTypes.string,
};

FeatureCardBlock.defaultProps = {
  backgroundColor: 'dark',
  body: null,
  cardsPerRow: '3',
  heading: null,
};

FeatureCardBlock.query = `
  ... on contentMatrix_featureCardBlock_BlockType {
    featureCardItems {
      ... on featureCardItems_featureCardItems_Entry {
        ${assetQuery('image')}
        slug
        title
        heading
        description
        internalLink {
          uri
        }
      }
    }
    ${matrixBlockQuery}
    backgroundColor
    body
    cardsPerRow
    heading
  }
`;

export { FeatureCardBlock };

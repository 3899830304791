import { isNil } from 'lodash';
import getVideoId from 'get-video-id';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';

import { logger } from '../../../utils/logger';

const videoServiceUrlMapping = {
  youtube: 'https://www.youtube.com/embed',
  vimeo: 'https://player.vimeo.com/video',
};

const IframeEmbed = ({ title, autoPlay, loop, controls, src, height, width }) => {
  const video = getVideoId(src);
  const baseUrl = videoServiceUrlMapping[video.service];
  const params = {
    autoplay: autoPlay,
    loop: controls ? 1 : 0,
    controls: controls ? 1 : 0,
    modestbranding: 1,
    rel: 0,
    playlist: loop ? video.id : undefined, // required for looping video
    mute: autoPlay ? 1 : 0,
  };

  if (isNil(baseUrl)) {
    logger.error(`Unsupported supported video service ${video.service}`);
    return null;
  }

  const iframeSrc = `${baseUrl}/${video.id}?${queryString.stringify(params)}`;

  return (
    <iframe
      width={width}
      height={height}
      src={iframeSrc}
      title={title}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

IframeEmbed.propTypes = {
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  height: PropTypes.number,
  loop: PropTypes.bool,
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
};

IframeEmbed.defaultProps = {
  title: null,
  autoPlay: false,
  controls: false,
  loop: false,
  height: 1920,
  width: 1080,
};

export { IframeEmbed };

import { Formik, Form as FormikForm } from 'formik';
import { isString } from 'lodash';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import size from 'lodash/size';
import styled from 'styled-components';

import { Box, Flex } from '../grid';
import { Button } from '../button';
import { P, Subheading } from '../typography';

const HeadingComponent = ({ heading, paragraph }) => {
  if (!heading) {
    return null;
  }

  if (isString(heading)) {
    return (
      <Subheading variant={2} textAlign="center" mb={paragraph ? '0.5rem' : 2}>
        {heading}
      </Subheading>
    );
  }

  return heading;
};
HeadingComponent.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  paragraph: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
HeadingComponent.defaultProps = { heading: undefined, paragraph: null };

const ParagraphComponent = ({ paragraph }) => {
  if (!paragraph) {
    return null;
  }

  if (isString(paragraph)) {
    return (
      <P textAlign="center" mb={2}>
        {paragraph}
      </P>
    );
  }

  return paragraph;
};
ParagraphComponent.propTypes = { paragraph: PropTypes.oneOfType([PropTypes.node, PropTypes.string]) };
ParagraphComponent.defaultProps = { paragraph: null };

const Form = ({
  buttonAnalytics,
  buttonText,
  children,
  disabled,
  error,
  heading,
  initialValues,
  onSubmit,
  paragraph,
  submitted,
  validationSchema,
  bottomElement,
  bottomPadding,
  topPadding,
  prefilled,
  formBackgroundColor,
  noXPadding,
  isFullWidthButton,
}) => (
  <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
    {({ dirty, errors, handleSubmit, isSubmitting }) => (
      <StyledForm>
        <Flex
          flexDirection="column"
          bg={formBackgroundColor}
          pt={topPadding}
          pb={bottomPadding}
          px={!noXPadding && { sm: 1, md: 3 }}
          width="100%"
        >
          <HeadingComponent heading={heading} paragraph={paragraph} />
          <Box px={!noXPadding && { _: '1.5rem', md: 2 }}>
            <ParagraphComponent paragraph={paragraph} />
            {children}
            {error && (
              <Box mb="1">
                <StyledError variant={2}>{error}</StyledError>
              </Box>
            )}
            <Button
              analytics={buttonAnalytics}
              disabled={disabled || size(errors) > 0 || isSubmitting || (!dirty && !prefilled) || submitted}
              isLoading={isSubmitting || undefined}
              onClick={handleSubmit}
              type="submit"
              width={isFullWidthButton ? '100%' : 'inherit'}
            >
              {buttonText}
            </Button>
            {bottomElement}
          </Box>
        </Flex>
      </StyledForm>
    )}
  </Formik>
);

const StyledForm = styled(FormikForm)`
  width: 100%;
`;

const StyledError = styled(P)`
  text-align: center;
  color: ${({ theme }) => theme.colors.errorPrimary};
`;

Form.propTypes = {
  buttonAnalytics: PropTypes.shape({}),
  buttonText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  paragraph: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  submitted: PropTypes.bool,
  validationSchema: PropTypes.shape({}).isRequired,
  bottomElement: PropTypes.node,
  bottomPadding: PropTypes.number,
  topPadding: PropTypes.number,
  prefilled: PropTypes.bool,
  formBackgroundColor: PropTypes.string,
  noXPadding: PropTypes.bool,
  isFullWidthButton: PropTypes.bool,
};

Form.defaultProps = {
  buttonAnalytics: undefined,
  disabled: false,
  error: null,
  heading: undefined,
  paragraph: null,
  submitted: false,
  bottomElement: null,
  bottomPadding: 3,
  topPadding: 2,
  prefilled: false,
  formBackgroundColor: 'white',
  noXPadding: false,
  isFullWidthButton: true,
};

const ComposedForm = observer(Form);
export { ComposedForm as Form };

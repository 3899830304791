import { useEffect } from 'react';
import { useRootStore } from '@nandosaus/state-management';

const useRestaurants = () => {
  const { RestaurantStore } = useRootStore();
  const { asArray, loaded, loading, loadRestaurants } = RestaurantStore;

  useEffect(() => {
    if (!loaded && !loading) {
      loadRestaurants();
    }
  }, [loaded, loading, loadRestaurants]);

  return [asArray, loading];
};

export { useRestaurants };

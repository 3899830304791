import { PropTypes } from 'prop-types';
import React from 'react';

import { cmsImagesPropTypes } from '../../prop-types';
import { imageTransform } from '../../content/transforms';
import { matrixBlockQuery, menuCarouselItemsQuery } from '../../content/query-snippets';
import { ProductCategoryCarousel } from '../carousel/product-category-carousel';

const MenuCarouselBlock = ({ items }) => {
  const blockItems = items.map(({ image, ...rest }) => ({
    image: imageTransform(image),
    ...rest,
  }));
  return <ProductCategoryCarousel items={blockItems} startPosition={1} />;
};

MenuCarouselBlock.query = `
  ... on contentMatrix_menuCarouselBlock_BlockType {
    ${matrixBlockQuery}
    ${menuCarouselItemsQuery}
  }
`;

MenuCarouselBlock.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      menuIcon: PropTypes.string.isRequired,
      image: cmsImagesPropTypes,
    })
  ).isRequired,
};

export { MenuCarouselBlock };

import { isEmpty, join, map, uniq } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import PropTypes from 'prop-types';
import React from 'react';

import { AccordionBlock } from '../accordion-block';
import { CareerOptionsBlock } from '../career-options-block';
import { ContentBlock } from '../content-block';
import {
  ContentImageBlock,
  ContentImageCollageBlock,
  FeatureBlock,
  HeroBlock,
  PageHeaderBlock,
} from '../content-image';
import { EnquiryFormBlock } from '../enquiry-form-block';
import { FeatureCardBlock } from '../feature-card-block';
import { IframeFormBlock } from '../iframe-form-block';
import { LinkCardBlock } from '../link-card-block';
import { MenuCarouselBlock } from '../menu-carousel-block';
import { MosaicBlock } from '../mosaic-block';
import { PageListingBlock } from '../page-listing-block';
import { PerksShopHighlightReelBlock } from '../perks-shop-highlight-reel-block';
import { RelatedEntriesBlock } from '../related-entries-block';
import { ValuesBlock } from '../values-block';
import { VideoContentBlock } from '../video-content-block';

const CONTENT_MATRIX_MAPPING = {
  accordionBlock: AccordionBlock,
  careerOptionsBlock: CareerOptionsBlock,
  contentBlock: ContentBlock,
  contentImageBlock: ContentImageBlock,
  contentImageCollageBlock: ContentImageCollageBlock,
  enquiryFormBlock: EnquiryFormBlock,
  featureBlock: FeatureBlock,
  featureCardBlock: FeatureCardBlock,
  heroBlock: HeroBlock,
  iframeFormBlock: IframeFormBlock,
  linkCardBlock: LinkCardBlock,
  menuCarouselBlock: MenuCarouselBlock,
  mosaicBlock: MosaicBlock,
  pageHeaderBlock: PageHeaderBlock,
  perksShopHighlightReelBlock: PerksShopHighlightReelBlock,
  pageListingBlock: PageListingBlock,
  relatedEntriesBlock: RelatedEntriesBlock,
  valuesBlock: ValuesBlock,
  videoContentBlock: VideoContentBlock,
};

const Placeholder = () => <div>Coming soon!</div>;

const ContentMatrix = ({ blocks, breadcrumbs, pageTitle }) => {
  const { BrazeStore } = useRootStore();

  if (isEmpty(blocks)) {
    return null;
  }

  return (
    <>
      {map(blocks, block => {
        const brazeMergedBlock = BrazeStore.getBrazeCmsContent(block);
        let Block = CONTENT_MATRIX_MAPPING[brazeMergedBlock.typeHandle];
        if (!Block) {
          Block = Placeholder;
        }
        return <Block key={block.id} {...{ ...brazeMergedBlock, breadcrumbs, pageTitle }} />;
      })}
    </>
  );
};

ContentMatrix.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      typeHandle: PropTypes.string.isRequired,
    })
  ),
  breadcrumbs: PropTypes.shape({
    title: PropTypes.string,
    ancestors: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }),
  pageTitle: PropTypes.string,
};

ContentMatrix.defaultProps = {
  blocks: [],
  breadcrumbs: {},
  pageTitle: '',
};

const buildContentMatrixElementQueryString = contentMatrixElements =>
  join(uniq(map(contentMatrixElements, contentMatrixElement => contentMatrixElement?.query)));

ContentMatrix.query = `
  contentMatrix {
    typeName: __typename
    ${buildContentMatrixElementQueryString(CONTENT_MATRIX_MAPPING)}
  }
`;
const ComposedContentMatrix = observer(ContentMatrix);
export { ComposedContentMatrix as ContentMatrix };

import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '../../grid';

const EnquiryFormFieldWrapper = ({ children }) => <Box mb={1}>{children}</Box>;

EnquiryFormFieldWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export { EnquiryFormFieldWrapper };

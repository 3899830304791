import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { Accordion } from '../accordion';
import { accordionItemsQuery, matrixBlockQuery } from '../../content/query-snippets';
import { analytics } from '../../analytics';
import { Col, Container, Row } from '../grid';
import { H3, RichText } from '../typography';
import { Icon } from '../icons';

const AccordionBlock = ({ blockTitle, menuIcon, items }) => {
  return (
    <Container m={1}>
      <Row>
        <Col width={{ xs: 1, md: 8 / 12, lg: 6 / 12 }} offset={[0, 0, 2 / 12, 3 / 12]}>
          <Icon name={menuIcon} height="35px" width="35px" />
          <H3 mb={1}>{blockTitle}</H3>
          {items.map(item => (
            <AccordionWrapper key={item.blockTitle}>
              <Accordion
                defaultExpanded={item.defaultExpanded}
                title={item.blockTitle}
                onStateChange={() =>
                  analytics.track(ANALYTICS_EVENTS.ACCORDION_BLOCK_CLICKED, {
                    text: item.blockTitle,
                  })
                }
              >
                <RichText keepMargins>{item.body}</RichText>
              </Accordion>
            </AccordionWrapper>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

const AccordionWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey200};

  :last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  }
`;

AccordionBlock.query = `
  ... on contentMatrix_accordionBlock_BlockType {
    ${matrixBlockQuery}
    blockTitle
    menuIcon
    ${accordionItemsQuery}
  }
`;

AccordionBlock.propTypes = {
  blockTitle: PropTypes.string.isRequired,
  menuIcon: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      blockTitle: PropTypes.string,
      body: PropTypes.string,
    })
  ).isRequired,
};

export { AccordionBlock };

import PropTypes from 'prop-types';
import React from 'react';

const VideoElement = ({
  captions,
  className,
  autoPlay,
  controls,
  loop,
  src,
  muted,
  height,
  mimeType,
  width,
  poster,
  ...props
}) => (
  <video
    {...props}
    autoPlay={autoPlay}
    controls={controls}
    height={height}
    loop={loop}
    muted={muted}
    poster={poster}
    width={width}
  >
    <track label="English" kind="captions" srcLang="en" src={captions} />
    <source src={src} type={mimeType} />
  </video>
);

VideoElement.propTypes = {
  captions: PropTypes.string,
  className: PropTypes.string,
  poster: PropTypes.string,
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  src: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  mimeType: PropTypes.string,
  muted: PropTypes.bool,
};

VideoElement.defaultProps = {
  captions: '',
  className: null,
  poster: null,
  autoPlay: false,
  controls: false,
  loop: false,
  height: 1920,
  width: 1080,
  mimeType: 'video/mp4',
  muted: false,
};

export { VideoElement };

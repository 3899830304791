import { ANALYTICS_EVENTS, colors } from '@nandosaus/constants';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { analytics } from '../../../analytics';
import { Box, Flex } from '../../grid';
import { Button } from '../../button';
import { cmsImagePropTypes } from '../../../prop-types';
import { Decal } from '../../decal';
import { Draggable } from '../draggable';
import { H2, H3 } from '../../typography';
import { Icon } from '../../icons';
import { MaskBox } from './item/mask-box';
import { navigation } from '../../../constants';
import { useIsMinWidth } from '../../../hooks/use-is-min-width';
import { Wrapper } from '../wrapper';

const LeftDecal = () => <Decal name="carouselFlame" zIndex={1} color="orange500" right={0} width="auto" height="92%" />;

const RightDecal = () => (
  <Decal
    name="carouselPepper"
    zIndex={1}
    color="perksBlue"
    left={0}
    width="auto"
    height="90%"
    transform="rotate(-5deg) translateX(-10%)"
  />
);

const ProductCategoryCarousel = ({ items, startPosition, displayItemNumber }) => {
  const [activeIndex, setActiveIndex] = useState(items.length >= startPosition ? startPosition : 0);
  const [isMobile, setIsMobile] = useState(false);
  const itemsToHandle =
    items.length > 0
      ? items
      : [
          {
            heading: 'More coming soon',
            image: { url: '/static/images/product/nandos_burger.jpg' },
            genericLink: navigation.MENU,
          },
        ];
  const activeItem = get(itemsToHandle, activeIndex);
  const mobileState = !useIsMinWidth('lg');
  useEffect(() => {
    setIsMobile(mobileState);
  }, [mobileState]);

  const onArrowClick = direction => {
    const newActiveIndex = direction === 'left' ? activeIndex - 1 : activeIndex + 1;
    if (newActiveIndex < 0 || newActiveIndex >= items.length) {
      return;
    }
    setActiveIndex(newActiveIndex);
  };

  const onDragEnd = direction => {
    if (direction === 'left') {
      onArrowClick('left');
    } else {
      onArrowClick('right');
    }
  };

  const shouldShowLeftArrow = activeIndex > 0;
  const shouldShowRightArrow = activeIndex < items.length - 1;

  return (
    <Wrapper
      onArrowClick={onArrowClick}
      decals={{ leftDecal: <LeftDecal />, rightDecal: <RightDecal />, decalChildrenStyle: { zIndex: undefined } }}
      pb={4}
    >
      <Box pt={2} pb={4} flexDirection="row" justifyContent="center" position="relative">
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <H2>Our Menu</H2>
        </Flex>
        <Box mt={{ xs: -2, md: 0 }}>
          <Draggable
            items={items}
            displayItemNumber={isMobile ? 1 : displayItemNumber}
            activeIndex={activeIndex}
            isMobile={isMobile}
            ItemComponent={MaskBox}
            onDragEnd={onDragEnd}
            xDragMargin={isMobile ? 0 : 50}
            transitionDuration={isMobile ? 0.2 : 0.4}
          />
        </Box>
        <Flex
          mt={{ _: 0, lg: 2 }}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          position="relative"
          zIndex={2}
        >
          <H3 maxWidth={{ xs: '60%', md: '100%' }} textAlign="center">
            {activeItem.heading}
          </H3>
          <Flex
            flexDirection="row"
            mt={{ xs: 3, md: 2 }}
            justifyContent="center"
            alignItems="center"
            width={{ xs: '80%', md: '60%' }}
          >
            {shouldShowLeftArrow ? (
              <Box
                position="absolute"
                left={{ xs: '5%', sm: '15%', md: '25%' }}
                onClick={() => onArrowClick('left')}
                as="button"
              >
                <Icon
                  name="leftArrow"
                  fill={colors.black}
                  width={{ xs: '12px', md: '24px' }}
                  height={{ xs: '12px', md: '24px' }}
                />
              </Box>
            ) : null}

            <Button
              href={activeItem.genericLink || navigation.MENU}
              onClick={() => {
                analytics.track(ANALYTICS_EVENTS.MENU_CAROUSEL_BLOCK_CLICKED, {
                  text: activeItem.heading,
                });
              }}
              textTransform="uppercase"
            >
              view more
            </Button>

            {shouldShowRightArrow ? (
              <Box
                position="absolute"
                right={{ xs: '5%', sm: '15%', md: '25%' }}
                onClick={() => onArrowClick('right')}
                as="button"
              >
                <Icon
                  name="rightArrow"
                  fill={colors.black}
                  width={{ xs: '12px', md: '24px' }}
                  height={{ xs: '12px', md: '24px' }}
                />
              </Box>
            ) : null}
          </Flex>
        </Flex>
      </Box>
    </Wrapper>
  );
};

ProductCategoryCarousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      image: cmsImagePropTypes,
      genericLink: PropTypes.string,
    })
  ).isRequired,
  displayItemNumber: PropTypes.number,
  startPosition: PropTypes.number,
};

ProductCategoryCarousel.defaultProps = {
  displayItemNumber: 3,
  startPosition: 0,
};

export { ProductCategoryCarousel };
